@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');

body {
  font-family: 'Secular One';
  background-color: #111111;
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Fame-Query-App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-name-1 {
  color: white;
  font-size: 48px;
}

.header-name {
  color: white;
}

.Search-Button {
  text-align: center;
}

.Fame-Query-Box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Search-Button {
  justify-content: space-between;
}

.clan-result-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  color:white;
  margin: 10px;
}

.clan-tank-count-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  color:white;
  margin: 10px;
}

.tank-count-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 2px;
}